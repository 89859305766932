import React from "react"
import ContactUsItem from "./ContactUsItem"

import { FaMapMarkerAlt, FaPhone, FaRegEnvelope } from "react-icons/fa"
const ContactUsItems = () => {
  return (
    <>
      <div className="row">
        <ContactUsItem wowDelay="0.2s" icon={<FaPhone />}>
          <h4>Phone Number</h4>
          <p>
            Mobile: +91 8281038708 <br />
          </p>
        </ContactUsItem>

        <ContactUsItem wowDelay="0.3s" icon={<FaMapMarkerAlt />}>
          <h4>Location</h4>
          <p>
            3/507, First Floor, City Tower, Muttom PO.Thodupuzha,
            <br /> PIN: 685587{" "}
          </p>
        </ContactUsItem>
      </div>
      <div className="row">
        <ContactUsItem wowDelay="0.4s" icon={<FaRegEnvelope />}>
          <h4>Email &amp; Web</h4>
          <p>
            <a href="mailto:exgratiasystems@gmail.com">
              exgratiasystems@gmail.com
            </a>
            <br />
            <a href="http://www.exgratia.in/" target="_blank">
              www.exgratia.in
            </a>
            <br />
          </p>
        </ContactUsItem>
        <ContactUsItem wowDelay="0.4s" icon={<FaRegEnvelope />}>
          <h4>Our Sister Concern</h4>
          <p>
            <a href="mailto:info@intimazioneglobal.com">
              info@intimazioneglobal.com
            </a>
            <br />
            <a href="https://www.intimazioneglobal.com" target="_blank">
              www.intimazioneglobal.com
            </a>
          </p>
        </ContactUsItem>
      </div>
    </>
  )
}

export default ContactUsItems
