import React from 'react'

const ContactUsHeading = () => {
	return (
		<div className="col-md-6 col-lg-6 col-md-offset-3 col-lg-offset-3 col-sm-12 col-xs-12 mx-auto">
			<div className="area-title text-center wow fadeIn">
				<h2>Contact Us</h2>
				<p>
					We want to hear from you. You can reach us through any of the
					following.
				</p>
			</div>
		</div>
	)
}

export default ContactUsHeading
