import React from 'react'
import Helmet from 'react-helmet'

const MapView = () => {
	return (
		<div className="bottom-map-area">
			<div id="map" style={{ width: '100%', height: '400px' }}>
				<Helmet>
					<script
						src="https://maps.googleapis.com/maps/api/js?key=MY_KEY&callback=initMap"
						async
						defer
					/>
				</Helmet>
			</div>
		</div>
	)
}

export default MapView
