import React from 'react'

import AboutItems from './AboutItems'
import AboutPic from './AboutPic'
import AboutDetails from './AboutDetails'

const About = () => {
	return (
		<section className="about-area  section-padding" id="about">
			<div className="container">
				<div className="row">
					<AboutPic />
					<div className="col-md-6 col-lg-7 col-sm-12 col-xs-12">
						<div className="about-content wow fadeIn">
							<AboutDetails />
							<AboutItems />
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default About
