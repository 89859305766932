import React from 'react'
import topMockUp from '../../../images/slider/top_mockup.png'

const Welcometextarea = () => {
	return (
		<>
			<div className="welcome-text-area">
				<div className="container">
					<div className="row">
						<div className="col-md-5 col-lg-5 col-sm-12 col-xs-12">
							<div className="welcome-text">
								<h1>EXGRATIA</h1>
								<h3>Systems</h3>
								<p>Out of good will for man kind</p>
								<div className="home-button">
									<a href="#">Take Tour</a>
								</div>
							</div>
						</div>
						<div className="col-md-7 col-lg-7 col-sm-12 col-xs-12">
							<div className="welcome-image">
								<img src={topMockUp} alt="top-mockup" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Welcometextarea
