import React from 'react'

const ContactUsItem = props => {
	return (
		<div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
			<div
				className="single-contact wow fadeInUp"
				data-wow-delay={props.wowDelay}
			>
				<div className="contact-icon">{props.icon}</div>
				<div className="contact-details">{props.children}</div>
			</div>
		</div>
	)
}

export default ContactUsItem
