import React from 'react'
import MaMatImage from '../../../images/projects/mamat.jpg'

const MalayarayaMatrimony = () => {
	return (
		<>
			<div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
				<div className="about-promo-content wow fadeInLeft">
					<h3>Malayaraya Matrimony </h3>
					<p>
						Malayaraya matrimony is a matrimonial solution exclusively for the
						Malayaraya people of Kerala. This matrimony targets around 1 lakhs
						people living in kerala. We are providing a web enabled solution and
						also an android/iphone app.
					</p>
					<a href="#" className="readmore mb-3">
						Coming soon
					</a>
				</div>
			</div>
			<div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
				<div className="about-promo-image text-center wow fadeInRight">
					<div className="member-image">
						<img src={MaMatImage} alt="mamat-image"></img>
					</div>
				</div>
			</div>
		</>
	)
}

export default MalayarayaMatrimony
