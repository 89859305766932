import React from 'react'

import Layout from '../components/layout'
import About from '../components/About/About'
import Footer from '../components/Footer/Footer'
import ContactUs from '../components/ContactUs/ContactUs'
import Header from '../components/Header/Header'
import Projects from '../components/Projects/Projects'
const IndexPage = () => (
	<>
		<Layout>
			<Header />
			<About />
			<Projects />
			<ContactUs />
			<Footer />
		</Layout>
	</>
)

export default IndexPage
