import React, { useState } from 'react'
import Logo from '../../../images/logo.png'
import {
	Collapse,
	Nav,
	Navbar,
	NavbarBrand,
	NavbarToggler,
	NavItem,
	NavLink,
} from 'reactstrap'
import UseHasScrolled from './UseHasScrolled'

const Navibar = () => {
	const [isOpen, setIsOpen] = useState(false)

	const toggle = () => setIsOpen(!isOpen)

	let colorOnScrollPosition = UseHasScrolled(200) ? 'dark' : '#42297d'
	return (
		<div className="mainmenu-area" id="mainmenu-area">
			<div className="mainmenu-area-bg"></div>
			<Navbar color={colorOnScrollPosition} expand="lg" fixed="top">
				<div className="container">
					<NavbarBrand href="/" className="ml-5">
						<img src={Logo} width="150" alt="logo" />
					</NavbarBrand>
					<NavbarToggler onClick={toggle} />
					<Collapse isOpen={isOpen} navbar>
						<Nav className="ml-auto mr-5" navbar>
							<NavItem>
								<NavLink href="#about">About Us</NavLink>
							</NavItem>
							<NavItem>
								<NavLink href="#projects">Projects</NavLink>
							</NavItem>
							<NavItem>
								<NavLink href="#contact">Contact Us</NavLink>
							</NavItem>

						</Nav>
					</Collapse>
				</div>
			</Navbar>
		</div>
	)
}

export default Navibar
