import React from 'react'

const AboutItem = props => {
	return (
		<div className="col-md-3 col-lg-3 col-sm-3 col-xs-6">
			<div
				className="single-fun-fact text-center wow fadeInUp"
				data-wow-delay={props.wowDelay}
			>
				<div className="fact-icon">{props.children}</div>
				{/*<h3 className="counter">1140</h3>*/}
				<p>{props.title}</p>
			</div>
		</div>
	)
}

export default AboutItem
