import React from "react"
import GisLogo from "../../../images/projects/gis_logo.png"

const GovtKer = () => {
  return (
    <>
      <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
        <div className="about-promo-image text-center wow fadeInRight">
          <div className="member-image">
            <img src={GisLogo} alt="mamat-image" className="imgproject"></img>
          </div>
        </div>
      </div>
      <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
        <div className="about-promo-content wow fadeInLeft">
          <h3>Government of Kerala Projects </h3>
          <h4> Mobile/Web Apps for KSCSTE - National Transportation Planning and Research Centre (KSCSTE - NATPAC)</h4>
          <p>
            The proposed solution consists of Mobile Apps and Web Admin portal for road data collection with tracks, points, road inventory, cross section details that will be collected by field officers across various roads in the state of Kerala. The data will be further analysed by GIS systems for various planning purposes.
          </p>
          {/* <a href="#" className="readmore mb-3">
            Coming soon
          </a> */}
        </div>
      </div>
    </>
  )
}

export default GovtKer