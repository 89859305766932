import React from 'react'
import MalayarayaMatrimony from './MalayarayaMatrimony/MalayarayaMatrimony'
import IBukt from './IBukt/IBukt'
import Erp from './ERP/Erp'
import GovtKer from './Goverment-of-Kerala/GovtKer'

const Projects = () => {
	return (
		<section className="skill-area gray-bg section-padding" id="projects">
			<div className="container">
				<div className="row">
					<div className="col-md-6 col-lg-6 col-md-offset-3 col-lg-offset-3 col-sm-12 col-xs-12">
						<div className="area-title text-center wow fadeIn">
							<h2>Our Projects</h2>
						</div>
					</div>
				</div>
				<div className="row">
					<MalayarayaMatrimony />
				</div>
				<br className="mt-5"/>
				<br className="mt-5"/>
				<div className="row mt-5">
					<IBukt/>
				</div>
				<br className="mt-5"/>
				<br className="mt-5"/>
				<div className="row">
					<Erp />
				</div>
				<br className="mt-5"/>
				<br className="mt-5"/>
				<div className="row">
					<GovtKer />
				</div>
			</div>
		</section>
	)
}

export default Projects
