import React from 'react'
import aboutPic from '../../images/about/about.jpg'

const AboutPic = () => {
	return (
		<div className="col-md-6 col-lg-5 col-sm-12 col-xs-12">
			<div className="about-image wow fadeIn">
				<img src={aboutPic} alt="about" />
			</div>
		</div>
	)
}

export default AboutPic
