import React from 'react'

const AboutDetails = () => {
	return (
		<div className="about-details">
			<h2>we are creative and modern</h2>
			<p>
				We mainly focus on developing products based on supply the needs of our
				end customers.
			</p>
			<p>
				Projects like iBukt, Iwanttogive, getmyguru etc are targeting all types
				of peoples across the globe, while malayarayamatrimony, agapestudy etc.
				targets exclusive clients.
			</p>
		</div>
	)
}

export default AboutDetails
