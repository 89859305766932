import React from "react"
import IBuktImage from "../../../images/projects/iBukt.jpg"

const IBukt = () => {
  return (
    <>
      <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
        <div className="about-promo-image text-center wow fadeInRight">
          <div className="member-image">
            <img src={IBuktImage} alt="mamat-image"></img>
          </div>
        </div>
      </div>
      <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
        <div className="about-promo-content wow fadeInLeft">
          <h3>iBukt </h3>
          <p>
            IBukt is a new generation e-commerce platform which allows the users to do real time
            shopping where ever you are. IBukt keeps your personal shopping bucket and intelligently
            suggests when to shop and where to purchase from.
          </p>
          <a href="#" className="readmore mb-3">
            Coming soon
          </a>
        </div>
      </div>
    </>
  )
}

export default IBukt