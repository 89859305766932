import React from 'react'
import Navbar from './Navbar/Navbar'
import Welcometextarea from './Welcometextarea/Welcometextarea'

const Header = () => {
	return (
		<div
			style={{
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<header className="top-area" id="home">
				<div className="top-area-bg" data-stellar-background-ratio="0.6"></div>
				<div className="header-top-area">
					<Navbar />
					<Welcometextarea />
				</div>
			</header>
		</div>
	)
}

export default Header
