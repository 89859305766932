import React from 'react'
import { FaPaperPlane, FaThumbsUp, FaStar, FaHeart } from 'react-icons/fa'
import AboutItem from './AboutItem'

const AboutItems = () => {
	return (
		<div className="row">
			<AboutItem title="Innovative Ideas" wowDelay="0.2s">
				<FaPaperPlane />
			</AboutItem>

			<AboutItem title="Amazing Projects" wowDelay="0.3s">
				<FaThumbsUp />
			</AboutItem>

			<AboutItem title="Highest ratings" wowDelay="0.4s">
				<FaStar />
			</AboutItem>

			<AboutItem title="Happy Clients" wowDelay="0.5s">
				<FaHeart />
			</AboutItem>
		</div>
	)
}

export default AboutItems
