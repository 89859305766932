import React from 'react'

const Footer = () => {
	return (
		<div className="footer-area dark-bg">
			<div className="container">
				<div className="row">
					<div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
						<div className="footer-copyright text-center wow fadeIn">
							<p>
								Copyright &copy; <a href="http://exgratia.in"> EXGRATIA</a> All
								Right Reserved.
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Footer
