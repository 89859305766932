import React from 'react'
import ErpImage from '../../../images/projects/Erp.png'

const Erp = () => {
	return (
		<>
			<div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
				<div className="about-promo-content wow fadeInLeft">
					<h3>ERP projects</h3>
					<p>
                    We deal with different Open source ERP projects that are fit for small & medium companies in healthcare, construction, Trading, manufacturing sectors. We deals with SaaS based ERPNEXT, Odoo projects actively and there are partners to develop and enhance these solutions. The modules like HR, Payroll, Accounts, CRM, Asset Management, Purchase & Sales, Multiple shops etc are managed using these solutions.
					</p>
					{/* <a href="#" className="readmore mb-3">
						Coming soon
					</a> */}
				</div>
			</div>
			<div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
				<div className="about-promo-image text-center wow fadeInRight">
					<div className="member-image">
						<img src={ErpImage} alt="mamat-image"></img>
					</div>
				</div>
			</div>
		</>
	)
}

export default Erp
