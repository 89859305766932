import React from "react"

import ContactUsHeading from "./ContactUsHeading"
import ContactUsItems from "./ContactUsItems"
import MapView from "./MapView"

const ContactUs = () => {
  return (
    <section className="contact-area dark-bg padding-top" id="contact">
      <div className="top-contact-area">
        <div className="container">
          <div className="row">
            <ContactUsHeading />
          </div>
          <div className="mx-auto ">
            <ContactUsItems />
          </div>
        </div>
      </div>
      <MapView />
    </section>
  )
}

export default ContactUs
